import {
  PromotionLabel,
  type PromotionLabelProps,
} from '@aph/ui/components/promotion-label/promotion-label';
import { type IArticle } from '../../generated/ArticlesClient';

type PromotionLabelsProps = Pick<IArticle, 'tags'> & Pick<PromotionLabelProps, 'size'>;

export const PromotionLabels = ({ tags, size }: PromotionLabelsProps) => {
  const hasTags = tags && tags.length > 0;
  if (!hasTags) {
    return null;
  }

  const flyer = tags?.find((tag) => tag.type === 'Flyer');
  const clubMemberFlyer = tags?.find((tag) => tag.type === 'ClubMemberFlyer');

  return (
    <>
      {flyer && (
        <PromotionLabel
          variant="promotion"
          size={size}
          className="whitespace-nowrap"
          aria-label={`Kampanj ${flyer.value}`}
        >
          {flyer.value}
        </PromotionLabel>
      )}
      {clubMemberFlyer && (
        <PromotionLabel
          variant="club"
          size={size}
          className="whitespace-nowrap"
          aria-label={`Kampanj för klubbmedlemmar ${clubMemberFlyer.value}`}
        >
          {clubMemberFlyer.value}
        </PromotionLabel>
      )}
    </>
  );
};
