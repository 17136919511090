import { CloudinaryImage } from '@aph/components/cloudinary-image/cloudinary-image';

export const ValvaldLabel = () => (
  <div className="bg-elevated border-subtle relative h-4 w-4 shrink-0 overflow-hidden rounded-[50%] border p-0.5">
    <CloudinaryImage
      publicId="valvald_logo"
      alt="Detta läkemedel uppfyller apotekens krav på ansvarsfull läkemedelstillverkning"
      layout="constrained"
      height={200}
      aspectRatio={4 / 3}
      objectFit="contain"
    />
  </div>
);
