import NextLink from 'next/link';
import { Box, Link as MuiLink, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AddArticleToCartComponent } from '@aph/components/product-variant/add-article-to-cart-button/add-article-to-cart.component';
import { useIsBot } from '@aph/hooks/use-is-bot';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import { formatProductUrl } from '@aph/utilities/format-product-url';
import type { IArticle } from '../../../generated/ArticlesClient';
import { useArticleCardTracking } from '../../../hooks/use-article-card-tracking/use-article-card-tracking';
import { ArticleImage } from '../../article-image/article-image.component';
import { ArticleLabels } from '../../article-labels/article-labels';
import { ValvaldLabel } from '../../article-labels/valvald-label';
import { ArticlePrice } from '../../article-price/article-price';
import { ArticleProductType } from '../../article-product-type/article-product-type';

type Article = IArticle & {
  sponsoredId?: string;
};

export type ArticleCardProps = {
  article: Article;
  index: number;
  listName: string;
};

export const ArticleCard = ({ article, listName, index }: ArticleCardProps) => {
  const { sendSelectItemEvent, cardRef } = useArticleCardTracking(article, listName, index);
  const isBot = useIsBot();

  const certifications = article.tags?.find((tag) => tag.type === 'Certification');
  const isValVald = certifications && certifications.value === 'Valvald';

  const isBlackWeekPromotion =
    article.price?.salesInformation?.flyerInformation?.style === 'Rectangular' &&
    article.price?.salesInformation?.flyerInformation?.hexColorCode === '#000';

  return (
    <Stack
      ref={cardRef}
      height="100%"
      data-testid="ArticleCard"
      padding={(theme) => theme.spacing(2, 2, 0, 2)}
      borderRadius={{ xs: 4, sm: 6 }}
      bgcolor={(theme) => theme.palette['color/background/elevated']}
      sx={{
        '&:hover': {
          boxShadow: '0px 0px 10px 0px #3031330D',
        },
      }}
      className={cn(
        isBlackWeekPromotion &&
          "[--promotion-label-bg-color:theme('colors.grey900')] [--promotion-label-text-color:theme('textColor.inverse')]",
      )}
    >
      <NextLink
        data-testid="ArticleCardLink"
        href={{
          pathname: formatProductUrl(article.articleUrlSegment),
          // we don't want to use trackingId for bots (SEO)
          query: article.trackingId && !isBot ? { ri: article.trackingId } : undefined,
        }}
        passHref
        legacyBehavior
        prefetch={false}
      >
        <MuiLink underline="none" flexGrow={1} onClick={() => sendSelectItemEvent()}>
          <Stack data-testid="ArticleCardContent" height="100%">
            <div className="relative">
              <div className="mt-6">
                <ArticleImage
                  src={article.images?.[0]}
                  alt=""
                  layout="constrained"
                  objectFit="contain"
                  width={300}
                  height={300}
                  shouldUseRxFallback={article.requiresPrescriptionForPurchase}
                />
                <Box position="absolute" top={0} width="100%">
                  <ArticleLabels tags={article.tags} />
                </Box>
              </div>
            </div>
            {isValVald ? (
              <div className="flex justify-end">{isValVald && <ValvaldLabel />}</div>
            ) : (
              <div className="h-4" />
            )}
            <Box height={(theme) => theme.spacing(2)} alignItems="center">
              <ArticleProductType productType={article.productType} />
            </Box>
            <Typography
              typography="headingExtraSmall"
              className="mb-1 line-clamp-3 flex-1 text-ellipsis whitespace-normal font-bold"
            >
              {article.name}
            </Typography>
            <ArticlePrice price={article.price} showRetailPrice={false} spaceBetween />
          </Stack>
        </MuiLink>
      </NextLink>
      <div className="sm:max-xl:hidden">
        <AddArticleToCartComponent
          article={article}
          size="small"
          index={index}
          listName={listName}
        />
      </div>
      <div className="hidden sm:max-xl:block">
        <AddArticleToCartComponent
          article={article}
          size="medium"
          index={index}
          listName={listName}
        />
      </div>
      <Box
        height={(theme) => theme.spacing(3.5)}
        display="flex"
        justifyContent="center"
        paddingTop={0.5}
      >
        {article.isWebSaleOnly && (
          <Typography
            data-testid="ArticleWebSalesOnlyInformation"
            typography="footnote"
            color="text-subtle"
          >
            <FormattedMessage id="COMMON.PRODUCT_CARD.IS_WEB_SALE_ONLY" />
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
