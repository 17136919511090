import Nyhet from '@aph/images/nyhet.svg';
import PangPris from '@aph/images/pangpris.svg';
import PrisVart from '@aph/images/prisvart.svg';
import { type IArticle } from '../../generated/ArticlesClient';
import { RosaBandetLabel } from './rosabandet-label';

export type PackagingLabelsProps = Pick<IArticle, 'tags'>;

export const PackagingLabels = ({ tags }: PackagingLabelsProps) => {
  const hasTags = tags && tags.length > 0;
  if (!hasTags) {
    return null;
  }
  const packaging = tags?.find((tag) => tag.type === 'Packaging');

  const isPrisVart = packaging && packaging.value === 'PrisVart';
  const isRosaBandet = packaging && packaging.value === 'RosaBandet';
  const isPangPris = packaging && packaging.value === 'PangPris';
  const isNyhet = packaging && packaging.value === 'Nyhet';
  const hasPackaging = isPrisVart || isRosaBandet || isPangPris || isNyhet;

  if (!hasPackaging) {
    return null;
  }

  return (
    <>
      {isRosaBandet && <RosaBandetLabel />}
      {isPangPris && (
        <PangPris className="font-ica-heading font-black" aria-label="Märkning Pangpris" />
      )}
      {isPrisVart && <PrisVart aria-label="Märkning Prisvärt från hjärtat" />}
      {isNyhet && <Nyhet className="font-bold" aria-label="Märkning Nyhet" />}
    </>
  );
};
