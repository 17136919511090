import { type IArticle } from '../../generated/ArticlesClient';
import { PackagingLabels } from './packaging-labels';
import { PromotionLabels } from './promotion-labels';

export type ArticleLabelsProps = Pick<IArticle, 'tags'>;

export const ArticleLabels = ({ tags }: ArticleLabelsProps) => {
  const hasTags = tags && tags.length > 0;
  if (!hasTags) {
    return null;
  }

  return (
    <div className="flex justify-between" data-testid="ArticleLabels">
      <div className="flex">
        <div className="flex flex-col gap-1">
          <div className="sm:max-xl:hidden" data-testid="ArticleLabelsPromotion">
            <PromotionLabels tags={tags} size="small" />
          </div>
          <div className="hidden sm:max-xl:block">
            <PromotionLabels tags={tags} size="large" />
          </div>
        </div>
      </div>
      <div className="flex w-[35%] flex-col items-end sm:w-[30%] xl:w-[35%]">
        <PackagingLabels tags={tags} />
      </div>
    </div>
  );
};
