import { useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { formatPrice, formatPriceAriaLabel } from '@aph/utilities/format-price/format-price';
import type { IArticle } from '../../generated/ArticlesClient';
import { PriceWithTitle } from './price-with-title';

type ArticlePriceProps = Pick<IArticle, 'price'> & {
  showRetailPrice?: boolean;
  spaceBetween?: boolean;
};

export const ArticlePrice = ({
  price,
  showRetailPrice = true,
  spaceBetween = false,
}: ArticlePriceProps) => {
  const { formatMessage } = useIntl();

  if (!price) {
    return null;
  }

  const salesPrice = price.salesPrice && formatPrice(price.salesPrice);
  const webPrice = price.webPrice && formatPrice(price.webPrice);
  const retailPrice = price.retailPrice && formatPrice(price.retailPrice);

  const salesPriceAriaLabel = formatPriceAriaLabel(price.salesPrice ?? 0);
  const webPriceAriaLabel = formatPriceAriaLabel(price.webPrice);

  const isPriceWithDiscount = Boolean(salesPrice && webPrice);

  return (
    <div data-testid="ArticlePrice" className="flex flex-col pb-1">
      <div className={`flex ${spaceBetween ? 'justify-between' : ''}`}>
        {salesPrice && (
          <PriceWithTitle
            price={salesPrice}
            isDiscounted
            title={formatMessage({ id: 'ARTICLE.CAMPAIGN' })}
            ariaLabel={salesPriceAriaLabel}
          />
        )}

        {webPrice && (
          <PriceWithTitle
            price={webPrice}
            strikeThrough={Boolean(salesPrice)}
            title={formatMessage({ id: 'ARTICLE.WEB' })}
            ariaLabel={
              isPriceWithDiscount ? `Tidigare pris, ${webPriceAriaLabel}` : webPriceAriaLabel
            }
          />
        )}
      </div>
      {showRetailPrice && retailPrice && (
        <Typography typography="footnote" color="text-subtle">
          {formatMessage({ id: 'ARTICLE.RETAIL' }, { price: retailPrice })}
        </Typography>
      )}
    </div>
  );
};
