/* eslint-disable prefer-arrow-callback */
import { forwardRef } from 'react';
import type { ImageProps } from 'next/image';
import NextImage from 'next/image';
import type { ContentfulLoaderParams } from './contentful-loader';
import { contentfulLoader } from './contentful-loader';

type ContentfulImageSource =
  | string
  | { url: string }
  | { file: { url: string } }
  | { fields: { file: { url: string } } };

export type ContentfulImageProps = Omit<ImageProps, 'src' | 'quality'> &
  ContentfulLoaderParams & {
    src: ContentfulImageSource;
    aspectRatio?: string;
  };

export const ContentfulImage = forwardRef<HTMLImageElement, ContentfulImageProps>(
  function ContentfulImage({ format, fit, quality, aspectRatio, ...props }, ref) {
    const contentfulImageOptions = {
      format,
      fit,
      quality,
    } satisfies ContentfulLoaderParams;

    return (
      <NextImage
        {...props}
        src={getContentfulImageSrcUrl(props.src)}
        loader={(loaderOptions) =>
          contentfulLoader(loaderOptions, { ...contentfulImageOptions, aspectRatio })
        }
        ref={ref}
      />
    );
  },
);

function getContentfulImageSrcUrl(src: ContentfulImageSource) {
  let url: string = src.toString();

  if (typeof src === 'object') {
    if ('fields' in src) {
      url = src.fields.file.url;
    } else if ('file' in src) {
      url = src.file.url;
    } else {
      url = src.url;
    }
  }

  // Prepend https: if necessary
  // eslint-disable-next-line prefer-template
  if (url.startsWith('//')) url = 'https:' + url;

  // eslint-disable-next-line prefer-destructuring
  if (url.includes('?')) url = url.split('?')[0];

  return url;
}
