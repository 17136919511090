import type { ImageLoaderProps } from 'next/image';

type ContentfulImageOptionsFormat =
  | 'jpg'
  | 'png'
  | 'webp'
  | 'gif'
  | 'avif'
  | 'jpg/progressive'
  | 'png/png8';

type ContentfulImageOptionsFit = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

type ContentfulImageOptionsWidth = number;
type ContentfulImageOptionsHeight = number;
type ContentfulImageOptionsQuality = number;

export type ContentfulLoaderParams = {
  format?: ContentfulImageOptionsFormat;
  fit?: ContentfulImageOptionsFit;
  quality?: ContentfulImageOptionsQuality;
};

export function contentfulLoader(
  loaderProps: ImageLoaderProps,
  contentfulParams?: ContentfulLoaderParams & { aspectRatio?: string },
) {
  const url = loaderProps.src;
  const query = mapToContentfulAssetQueryOptions({
    format: contentfulParams?.format ?? 'webp',
    fit: contentfulParams?.fit,
    width: loaderProps.width,
    height: contentfulParams?.aspectRatio
      ? Math.round(loaderProps.width * parseAspectRatio(contentfulParams.aspectRatio))
      : undefined,
    quality: loaderProps.quality ?? contentfulParams?.quality ?? 75,
  });

  return url + (query ? `?${query}` : '');
}

const parseAspectRatio = (aspectRatio: string) => {
  if (!aspectRatio.includes('/')) {
    throw new Error('Syntax not recognized for aspect ratio argument (ex: 4/3, 16/9)');
  }

  const [w, h] = aspectRatio.split('/');
  return Number(h) / Number(w);
};

const optionQueryKeys: Record<keyof ContentfulImageOptions, string[]> = {
  format: ['fm', 'fl'],
  fit: ['fit'],
  quality: ['q'],
  width: ['w'],
  height: ['h'],
};

type ContentfulImageOptions = ContentfulLoaderParams & {
  width?: ContentfulImageOptionsWidth;
  height?: ContentfulImageOptionsHeight;
};

function mapToContentfulAssetQueryOptions(options: ContentfulImageOptions) {
  return Object.entries(options)
    .map(([key, value]) => {
      const queryKeys = optionQueryKeys[key as keyof ContentfulImageOptions];

      // "/" is needed because of jpg/progressive for example
      const values = value?.toString()?.split('/') ?? [];

      // By index, match each parameter name and value to a "{name}={value}" pair.
      return queryKeys
        .map((name, i) => {
          if (!name || !values[i]) return '';
          // eslint-disable-next-line prefer-template
          return name + '=' + values[i];
        })
        .filter((_) => !!_); // Remove all empty values
    })
    .flat()
    .join('&'); // Join all {name}={value} pairs with "&" to a query string.
}
