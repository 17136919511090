import { sendEcommerce } from '@aph/components/gtm/useGtm';
import { ImageLink } from '~/contentful/components/image-link/image-link';
import type { IImageLink } from '~/contentful/types';

interface BannerCardProps {
  banner: IImageLink | undefined;
}

const getTrackingData = (banner: Pick<IImageLink, 'fields' | 'sys'>) => ({
  creative_name: banner.fields.title,
  creative_slot: 'jm_banner',
  promotion_id: banner.sys.id,
  promotion_name: 'category page jm banner',
});

export const BannerCard = ({ banner }: BannerCardProps) => {
  const sendViewPromotion = () => {
    if (banner) {
      sendEcommerce({
        event: 'view_promotion',
        ecommerce: getTrackingData(banner),
      });
    }
  };

  const sendSelectPromotion = () => {
    if (banner) {
      sendEcommerce({
        event: 'select_promotion',
        ecommerce: getTrackingData(banner),
      });
    }
  };

  return (
    <div className="order-3 col-span-2 h-fit w-auto place-self-start sm:order-4 lg:order-5 xl:order-7">
      <ImageLink
        alt={
          (banner?.fields?.image?.fields.description || banner?.fields?.image?.fields.title) ?? ''
        }
        height={banner?.fields?.image?.fields.file.details.image?.height}
        width={banner?.fields?.image?.fields.file.details.image?.width}
        src={banner?.fields?.image?.fields.file.url ?? ''}
        href={banner?.fields?.link}
        style={{ objectFit: 'cover', height: '100%', width: '100%' }}
        onInView={sendViewPromotion}
        onClick={sendSelectPromotion}
      />
    </div>
  );
};
