import { CloudinaryImage } from '@aph/components/cloudinary-image/cloudinary-image';

export const RosaBandetLabel = () => (
  <div className="flex h-[70px] w-[40px]">
    <CloudinaryImage
      publicId="rosa_bandet"
      alt="Denna produkt stödjer Cancerfondens arbete mot bröstcancer, rosa bandet."
      layout="constrained"
      width={200}
      height={200}
      objectFit="contain"
    />
  </div>
);
