import { Skeleton, Stack } from '@mui/material';

export const ArticleCardSkeleton = () => (
  <Stack
    data-testid="ArticleCardLoader"
    padding={(theme) => theme.spacing(2, 2, 0, 2)}
    borderRadius={7}
    bgcolor={(theme) => theme.palette['color/background/elevated']}
    gap={2}
    paddingBottom={3}
  >
    <Skeleton variant="rectangular" sx={{ height: { xs: 165, lg: 180 } }} />
    <Skeleton variant="text" height={36} />
    <Skeleton variant="text" height={36} width="50%" />
    <Skeleton variant="rounded" height={33} />
  </Stack>
);
